import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { Navigate } from "@tanstack/react-router";
import type { MetalLeaseCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { type ReactNode, useMemo } from "react";
import { Input } from "react-aria-components";
import { Controller, type SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { pipe, reverse, sortBy } from "remeda";
import { controllerProps, formPropsToAriaProps } from "../../components/forms/ariaController.tsx";
import { CreateFormAction } from "../../components/forms/CreateFormAction.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { IconRadio, type IconRadioContentProps } from "../../components/forms/IconRadio.tsx";
import { MonthlyCostElement, MonthlyCostField } from "../../components/forms/MonthlyCostField.tsx";
import { RadioGroupDiv } from "../../components/forms/RadioGroupDiv.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { WTextField, type WTextFieldProps } from "../../components/forms/WTextField.tsx";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { Separator } from "../../components/Separator.tsx";
import { cn } from "../../utils/classNames.ts";
import { castSetter } from "../../utils/getField.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useOnce } from "../../utils/react/useOnce.ts";
import { useGenerateUuid } from "../../utils/useGenerateUuid.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { BillingAccountField } from "../billing/forms/BillingAccountField.tsx";
import { LocationFieldCustom } from "../location/LocationField.tsx";
import { useLocationsForType } from "../location/query.ts";
import { pricesAtom } from "../pricing/query.ts";
import { getBareMetalSpecPrice, type ResourcePrices } from "../pricing/resourcePricing.ts";
import { leaseLink } from "./links.ts";
import { type MetalCreateInputs, MetalCreateViewModel } from "./metalCreateViewModel.tsx";
import { metalOsQueryAtom } from "./metalOsQuery.ts";
import { leaseCreateMutation } from "./metalQuery.ts";
import { specQueryAtom } from "./metalSpecQuery.ts";
import { MetalOsSelect } from "./os/MetalOsSelect.tsx";
import { getSpecsForLocation } from "./spec/spec.ts";
import { SpecRadio } from "./spec/SpecRadio.tsx";

interface ItemType extends IconRadioContentProps {
    description: ReactNode;
}

const availableTypes = [
    {
        id: "bare_metal",
        title: "Bare Metal",
        icon: "jp-host-icon",
        description: "",
    },
] as const satisfies ItemType[];

interface MetalCreateProps {
    inputs?: Partial<MetalCreateInputs>;
}

export function MetalCreate(props: MetalCreateProps) {
    return <MetalCreateForm {...props} />;
}

export function MetalCreateForm({ inputs }: MetalCreateProps) {
    //#region Hooks
    const allImages = useSuspenseQueryAtom(metalOsQueryAtom);
    const specs = useSuspenseQueryAtom(specQueryAtom);
    const priceList = useSuspenseQueryAtom(pricesAtom);

    const mutation = useStandardMutation(leaseCreateMutation);

    const locations = useLocationsForType("bare_metal");

    const requestUuid = useGenerateUuid(undefined);

    const viewModel = useOnce(() => new MetalCreateViewModel(allImages, locations, [...specs.values()]));

    const defaultValues = useMemo(() => {
        return {
            ...viewModel.getDefaultValues(inputs),
            ...inputs,
        };
    }, [viewModel, inputs]);

    /// Form
    const form = useForm<MetalCreateInputs>({
        disabled: mutation.isPending,

        mode: "onBlur",
        reValidateMode: "onBlur",

        defaultValues,
    });

    //#endregion Hooks

    if (mutation.isSuccess) {
        return <Navigate {...leaseLink(mutation.data)} />;
    }

    const { watch, control, setValue, getValues } = form;

    viewModel.attachGetterAndSetter(getValues, castSetter(setValue));

    const onSubmit: SubmitHandler<MetalCreateInputs> = async (inputs) => {
        const { location, ...rest } = inputs;
        const body: MetalLeaseCreateBody = {
            ...rest,
            requested_os_id: notNull(rest.requested_os_id),
            spec_uuid: notNull(rest.spec_uuid),
            billing_account_id: notNull(rest.billing_account_id),
        };

        await mutation.mutateAsync({
            requestUuid,
            inputs,
            body,
            location,
        });
    };

    const locationSpecs = getSpecsForLocation([...specs.values()], watch("location"));
    const locationReadyOsIds = locationSpecs.flatMap((item) => item.ready_os_ids);

    const spec_uuid = watch("spec_uuid");
    const spec = locationSpecs.find((item) => item.uuid === spec_uuid);

    const sortedImages = {
        ...allImages,
        os: pipe(
            allImages.os,
            // Newer versions first (assume they are last in the list)
            reverse(),
            // Available OSes first
            sortBy((item) =>
                locationReadyOsIds.includes(item.os_id) ? (spec?.ready_os_ids.includes(item.os_id) ? 1 : 2) : 3,
            ),
        ),
    };

    const readyOsIds = spec ? spec.ready_os_ids : locationReadyOsIds;

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <CreateHero />

            <WRadioGroup label="Type" isRequired value={availableTypes[0].id}>
                {availableTypes.map((item) => (
                    <IconRadio key={item.id} {...item} />
                ))}
            </WRadioGroup>

            <FormField wide label="OS" isRequired>
                <RadioGroupDiv>
                    <Controller
                        control={control}
                        name="requested_os_id"
                        render={({ field }) => (
                            <MetalOsSelect
                                data={sortedImages}
                                ready_os_ids={readyOsIds}
                                value={field.value ?? undefined}
                                onChange={viewModel.onChangeOs}
                            />
                        )}
                    />
                </RadioGroupDiv>
            </FormField>

            {locations.showLocations && (
                <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                        <LocationFieldCustom
                            value={field.value}
                            onChange={viewModel.onLocationChange}
                            locations={locations}
                        />
                    )}
                />
            )}

            <Controller
                control={control}
                name="spec_uuid"
                render={({ field }) => (
                    <WRadioGroup
                        wide
                        label="Server class"
                        isRequired
                        value={field.value}
                        onChange={viewModel.onSpecChange}
                    >
                        {locationSpecs
                            // XXX: Backward compat, remove
                            .filter((item) => item.is_visible !== false)
                            .map((item) => (
                                <SpecRadio
                                    wide
                                    icon="jp-cluster-icon"
                                    value={item.uuid}
                                    key={item.uuid}
                                    price={getBareMetalSpecPrice(priceList, watch("location"), item.uuid)}
                                    {...item}
                                />
                            ))}
                    </WRadioGroup>
                )}
            />

            <Separator />

            <MetalNameField isRequired />

            <Separator />

            <MetalMonthlyCostField uuid={watch("spec_uuid")} location={watch("location")} priceList={priceList} />

            <BillingAccountField resourceType="bare_metal" isRequired />

            <Separator />

            <CreateFormAction resourceType="bare_metal" />
        </WHookForm>
    );
}

function MetalNameField(textFieldProps: WTextFieldProps) {
    const { control } = useFormContext<MetalCreateInputs>();
    return (
        <Controller
            control={control}
            name="display_name"
            {...controllerProps(textFieldProps)}
            render={(props) => (
                <WTextField label="Resource name" {...formPropsToAriaProps(props)} {...textFieldProps}>
                    <Input ref={props.field.ref} className={cn(FF.FormFieldInput, TF.Input)} />
                </WTextField>
            )}
        />
    );
}

function MetalMonthlyCostField({
    uuid,
    priceList,
    location,
}: {
    uuid: string | null;
    location: string;
    priceList: ResourcePrices;
}) {
    if (uuid == null) {
        return null;
    }

    const price = getBareMetalSpecPrice(priceList, location, uuid);

    return (
        <MonthlyCostField
            description={
                <>
                    The prices is approximate, actual cost of your resource will be calculated based on your actual
                    hourly usage.
                </>
            }
        >
            <MonthlyCostElement price={price} />
        </MonthlyCostField>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Resource" icon="jp-host-icon">
            <p className="text-muted">
                Create a new Virtual Machine or choose something from the App Catalog to get your services running in
                minutes.
            </p>
        </HeroBlock>
    );
}
